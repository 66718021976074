const getTodayDateString = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

const activityLevels = {
  almost_every_day: 4,
  several_times_per_week: 3,
  several_times_per_month: 2,
  never: 1,
};

export const registerPayload = (code: string, quizAnswers: any) => {
  const userRTDBProperties = {
    weightHistory: {
      [getTodayDateString()]: quizAnswers?.current_weight?.kg,
    },
    userDetails: {
      isQuizComplete: true,
      code: code,
      startingWeight: quizAnswers?.current_weight?.kg,
      goalWeight: quizAnswers?.target_weight?.kg,
      gender: quizAnswers?.gender,
      height: quizAnswers?.height?.cm,
      activityLevel: activityLevels[quizAnswers?.exercise],
      age: quizAnswers?.age?.year,
      unitsOfMeasure: 'imperial',
      avoidIngredients: 'none',
    },
  };

  return userRTDBProperties;
};
